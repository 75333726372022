<template>
  <div v-if="info.id" :class="$style.report">
    <div :class="$style.head">
      <img :class="$style.icon" :src="info.cover[0].path" />
      <div>
        <p class="mb-4 ellipsis">
          {{ info.form.name }}
        </p>
        <BjTag :class="$style.state" :type="info.activity_status === 2 ? 'primary' : 'default'">{{
          info.activity_status | stateName
        }}</BjTag>
      </div>
    </div>
    <div :class="$style.content">
      <a-row>
        <a-col span="12" :class="$style.title"> 报名信息 </a-col>
        <a-col span="12" class="text-right">
          <BjButton v-permission="'resources.poc.group-create'" type="primary" @click="onPort()">
            <i class="ri-download-line left" />
            导出数据
          </BjButton>
        </a-col>
      </a-row>
      <div :class="$style.box">
        <p>
          关联表单:
          <span class="primary">{{ info.form.name }}</span>
        </p>
        <p>
          回收方式:
          <span class="primary">{{ info.form.recycle }}</span>
        </p>
      </div>

      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :scroll="{ x: columns.length * 300 }"
        :pagination="false"
        row-key="id"
      >
        <template #user="item">
          <img :class="$style.cover" :src="item.user.avatar" />
          <span class="primary">{{ item.user.nickname }}</span>
        </template>
        <template #action="item">
          <bj-link type="primary" @click="onDetail(item)"> 详情 </bj-link>
          <a-popconfirm
            title="确定要删除表单吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
            @confirm="onDelete(item)"
          >
            <bj-link class="ml-4" type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>

    <bj-modal :class="$style.modal" title="详情" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div>
        <div v-if="detail.is_sign === 1" :class="$style.sn">
          <div :class="$style.snleft">
            <BjTag :type="detail.status === 0 || detail.status === 1 ? 'primary' : 'default'">{{
              detail.status | statusName
            }}</BjTag>
            <div>SN: {{ detail.sn }}</div>
          </div>
          <div v-if="detail.status === 1" :class="$style.snright">
            <BjButton type="primary" @click="writeOff()">
              <i class="ri-check-double-line left" />
              立即核销
            </BjButton>
            <BjButton class="ml-20" @click="onUnsubscribe()">
              <i class="ri-file-download-line left" />
              预订退订
            </BjButton>
          </div>
        </div>
        <div v-if="detail.is_sign === 1" :class="$style.line" />
        <div :class="$style.box">
          <div :class="$style.left">提交用户</div>
          <div :class="$style.right">
            <img :src="detail.user.avatar" />
            <span class="primary">{{ detail.user.nickname }}</span>
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">登记编号</div>
          <div :class="$style.right">
            {{ detail.sign_no }}
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">提交时间</div>
          <div :class="$style.right">
            {{ detail.created_at }}
          </div>
        </div>
        <div v-if="detail.is_pay" :class="$style.box">
          <div :class="$style.left">报名费用</div>
          <div :class="$style.right">{{ detail.pay_amount }} 元</div>
        </div>
        <div :class="$style.line" />
        <div v-for="(item, index) in tableName" :key="index" :class="$style.box">
          <div :class="$style.left">{{ item.title }}</div>
          <div :class="$style.right">{{ detail[item.dataIndex] }}</div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { appActivityManage } from '@/service'

const service = new appActivityManage()

export default {
  name: 'report',
  filters: {
    stateName(val) {
      return val === 1 ? '未开始' : val === 2 ? '进行中' : '已结束'
    },
    statusName(val) {
      const obj = {
        0: '待支付',
        1: '未核销',
        2: '已核销',
        3: '已退订',
        4: '已过期',
      }
      return obj[val]
    },
  },
  data() {
    return {
      info: {},
      detail: {
        user: {},
      },
      tableName: [],
      loading: false,
      data: [],
      page: 1,
      pageSize: 20,
      total: 0,
      visible: false,
      columns: [
        {
          title: '提交用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        { title: '登记编号', dataIndex: 'sign_no' },
        { title: '提交时间', dataIndex: 'created_at' },
        {
          title: '操作',
          fixed: 'right',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getTable() {
      const { data } = await service.getSimple({
        id: this.info.form.id,
      })
      this.tableName = data.table
      if (data.table.length) {
        this.columns.splice(3, 0, ...data.table)
      }
    },
    async getInfo() {
      const { data } = await service.getInfoSimple({
        id: this.$route.query.id,
      })
      this.info = data
      this.getList()
      this.getTable()
    },
    async getList() {
      const { data } = await service.getListUser({
        page: this.page,
        page_size: this.pageSize,
        form_id: this.info.form.id,
      })
      this.data = data.record
      this.total = data.total
    },
    async onPort() {
      const { data } = await service.report({
        id: this.info.form.id,
      })
      window.open(data.export_url)
    },
    onDetail(item) {
      this.visible = true
      this.detail = item
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    async writeOff() {
      try {
        await service.writeOff({
          sn: this.detail.sn,
        })
        this.getList()
        this.$message.success('操作成功')
        this.visible = false
      } catch (e) {}
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onDelete(item) {
      try {
        await service.deleteFormRecord({
          id: item.id,
        })
        this.getList()
        this.$message.success('删除成功')
      } catch (e) {}
    },
    async onUnsubscribe() {
      try {
        await service.unsubscribe({
          id: this.detail.id,
        })
        this.getList()
        this.$message.success('操作成功')
        this.visible = false
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.report {
  .head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    background: #fff;
    font-size: 16px;
    color: #000;

    .icon {
      width: 56px;
      height: 56px;
      margin-left: 14px;
      margin-right: 14px;
      border-radius: 6px;
    }

    .state {
      font-size: 13px;
      color: #5c5c5c;
    }
  }

  .content {
    background: #fff;
    padding: 20px;
    margin-top: 20px;

    .title {
      font-size: 16px;
      color: #000;
    }

    .box {
      background: #fafafa;
      height: 76px;
      padding: 14px;
      margin-bottom: 20px;
      margin-top: 20px;

      p {
        margin-bottom: 10px;
      }
    }
  }

  .cover {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 10px;
  }
}

.modal {
  .sn {
    display: flex;
    .snleft {
      width: 230px;
      font-size: 12px;
      color: #5c5c5c;
    }
    .snright {
      text-align: right;
      flex: 1;
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #eee;
    margin: 20px 0;
  }

  .box {
    display: flex;
    font-size: 14px;
    line-height: 32px;

    .left {
      width: 100px;
    }

    .right {
      flex: 1;

      img {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 10px;
      }
    }
  }
}
</style>
